/* ANIMATER ARROW */
.arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.round-border {
    cursor: pointer;
    border: 3px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 1px;
}

.arrow {
    font-weight: bold;
    width: 10px;
    height: 10px;
    position: relative;
    width: 12px;
    height: 12px;
    background-size: contain;
    z-index: 4;
}

.down {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.next {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }

    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }

    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.arrow.primary.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.arrow-container:hover .arrow {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.arrow-container:hover .arrow.primary {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


/* GOOGLE PLAY BUTTON */
.gp-btn {
    background-color: #fff;
    color: #3a5f51;
    padding: 12px 30px;
    margin-top: 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    max-width: 222px;
    max-height: 42px;
}

.btn-flex {
    display: flex;
    flex-direction: row;
    padding: 0;
}

.arrow-icon {
    font-size: 1.9rem;
}

/* PRODUCT SERVICE BUTTON */
.ps-btn {
    background-color: #fff;
    color: #3a5f51;
    padding: 12px 30px;
    margin-top: 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

@media (max-width: 1350px) {
    .gp-btn {
        font-size: 0.8rem;
    }
}

@media (max-width: 1250px) {
    /* todo */
}

@media (max-width: 700px) {
    /* arrow button */
    .round-border {
        width: 34px!important;
        height: 34px!important;
    }
    /* PRODUCT BUTTON */
    .gp-btn {
        font-size: 0.8rem;
    }

}