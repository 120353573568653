.content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 20px;
    justify-items: center;
    align-items: start;
    padding: 20px 20px 40px 20px;
    min-height: 100vh;
    height: 100%;
    background: transparent;
    position: relative;
    z-index: 1;
}

.p-container {
    position: absolute;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-image: url("../../assets/agri-lp-cover3.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(35%);
}

.card {
    position: relative;
    z-index: 3;
    border-radius: 2px;
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
}

.card:hover {
    opacity: 0.9;
}

.pr-group {
    color: #4f5350;
    font-size: 0.8rem;
    /* text-shadow: 1px 1px 1px #4f5350; */
}

.card-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.card-header {
    color: #4f5350;
    margin: 10px 0;
    text-align: start;
    white-space: wrap;
    text-overflow: ellipsis;
    padding: 10px 0 0 0;
    box-sizing: border-box;
    font-size: 1.1rem;
    /* text-shadow: 1px 1px 1px #4f5350; */
}

.card-header2 {
    font-size: 1.1rem;
    color: #4f5350;
    text-align: start;
    align-self: start;
    white-space: wrap;
    text-overflow: ellipsis;
    width: 90%;
    box-sizing: border-box;
    /* text-shadow: 1px 1px 1px #4f5350; */
}

.card-text {
    font-size: 1.1rem;
    color: #4f5350;
    text-align: start;
    margin-bottom: 20px;
    box-sizing: border-box;
    align-self: start;
    justify-self: center;
    font-weight: bold;

}

.try-btn {
    width: 50%;
    background-color: transparent;
    border: 1px solid #4f5350;
    color: #4f5350;
    font-weight: bold;
    box-sizing: border-box;
    padding: 0.325rem;
    display: flex;
    justify-content: center;
    justify-self: flex-end;
    /* text-shadow: 1px 1px 1px #4f5350; */
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 550px) {
    .content {
        padding: 10px 10px 40px 10px;
    }

    .card .card-content h3, .card .card-content p{
        font-size: 1rem;
    }
}