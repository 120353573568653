.privacy-policy-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: hidden;
}

.privacy-policy-window {
  background: #fff;
  padding: 20px;
  max-width: 80%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.privacy-policy-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

p {
  font-size: 16px;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table,
th,
td {
  border: 1px solid #000;
}

th,
td {
  padding: 8px;
  text-align: left;
}