.bg-cover {
  background-image: url("../../assets/agri-lp-cover3.jpg");
  min-height: calc(100vh - 60px);
  width: 100%;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
}

.products-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  box-sizing: border-box;
  padding: 70px 50px 50px 50px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  min-width: 220px;
  min-height: 220px;
}

.p-logo1 {
  font-size: 2.5rem;
  height: 7rem;
}

.p-logo2 {
  font-size: 2.5rem;
  height: 5rem;
}

.p-logo3 {

  width: 180px;
}

.main-p-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  text-decoration: underline;
  font-size: 2.5rem;
}

.product-item-container1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 400px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  align-self: center;
  z-index: 3;
}

.column {
  flex-direction: column !important;
}

.product-item-container2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 500px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  align-self: center;
  z-index: 3;
}

.product-item-container3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  align-self: center;
  z-index: 3;
}

.product-item-container4 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 400px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  align-self: center;
  z-index: 3;
}

.product-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.width40 {
  width: 40%;
  flex: 2 !important;

}

.width60 {
  width: 60%;
  flex: 3 !important;
}


.item-header {
  color: #fff;
  font-size: 1.5rem;
}

.item-description {
  color: #fff;
  font-size: 1rem;
}

.margin-top {
  margin-top: 4rem;
  padding: 5px 5px 5px 10px;
}

.btn-group-container {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn-group-container a {
  display: flex;
  max-height: 40px;
  min-width: 220px;
  height: 40px;
  margin: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn-group-container a .arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -5px;
}
.ss {
  display: flex;
  justify-content: center !important;
  align-items: center;
  max-width: 50%;
  background-repeat: no-repeat;
  background-size: contain;

}

.ss img {
  height: 450px;
  object-fit: cover;
  border-radius: 6px;
  margin: 10px;
  background-repeat: no-repeat;
  background-size: contain;
}

.ss2 {
  display: flex;
  justify-content: center !important;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.ss2 img {
  height: 320px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}

.ss3 {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  gap: 10px;

}

.ss3 img {
  flex: 1;
  height: 320px;
  max-width: 50%;
  object-fit: cover;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
}

.ss4 {
  border-radius: 6px;
  margin: 10px;
}

.split {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #fff;
  height: 2px;
  width: 30%;
  align-self: center;
  position: relative;
  z-index: 5;
}

.space {
  margin-right: 5px;
  margin-left: 5px;
}

@media (max-width: 914px) {
  .product-item-container1 {
    flex-direction: column;
  }

  .product-item-container2 {
    flex-direction: column;
  }

  .ss {
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .width40 {
    width: 100%;
  }

  .width60 {
    width: 100%;
  }

  .margin-top {
    margin-top: 0;
    padding: 0;
  }

  .ss3 img {
    margin: 0;
  }

  .ss4 {
    width: 100%;
    margin: 10px 0;
    padding: 0;
  }
}

@media (max-width: 700px) {
  .main-p-header {
    flex-direction: column-reverse;
  }
}

@media (max-width: 550px) {
  .btn-group-container {
    flex-direction: column;
  }

  .ss3 {
    flex-direction: column;
  }

  .ss3 img {
    width: 100%;
    max-width: 100%;
  }
}