.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.service-card {
  background-color: rgba(74, 134, 33, 0.8);
  color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: calc(30.33% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.service-card2 {
  background-color: rgba(255, 255, 255, 0.9);
  color: #3a5f51;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: calc(30.33% - 20px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.service-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h2 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

.service-card:hover {
  transform: scale(1.05);
}

/* GWCT TAGS */
.key-principles {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
}

.key-principle {
  width: calc(70% - 20px);
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: transform 0.2s;
  text-shadow: 1px 1px 1px #d3dbd5;
}

.key-principle h3 {
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.key-principle ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.key-principle li {
  font-size: 16px;
  margin: 5px 0;
}

.key-principle:hover {
  transform: scale(1.05);
}

@media (max-width: 1450px) {
  .key-principle {
    width: calc(29% - 20px);
  }
}

@media (max-width: 1050px) {
  .key-principle {
    width: calc(43.5% - 20px);
  }
}

@media (max-width: 720px) {
  .key-principle {
    width: calc(88% - 20px);
  }
}

@media (max-width: 1450px) {

  .service-card,
  .service-card2 {
    width: calc(29% - 20px);
  }
}

@media (max-width: 1050px) {

  .service-card,
  .service-card2 {
    width: calc(43.5% - 20px);
  }
}

@media (max-width: 720px) {

  .service-card,
  .service-card2 {
    width: calc(88% - 20px);
  }
}