body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: scroll;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.body::-webkit-scrollbar {
  display: none;
}

/* NAVIGATION */
header {
  background-color: #ffff;
  position: fixed;
  z-index: 4;
  width: 100%;
  color: #4A8621;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

nav {
  display: flex;
  font-size: 1rem;
  line-height: 1.6em;
  box-sizing: border-box;
  height: 100%;
}

.nav a {
  text-decoration: none;
  color: #4A8621;
}

.nav .partners-link:hover {
  cursor: pointer;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  line-height: normal;
  vertical-align: baseline;
  align-items: center;
  height: 100%;
}

nav li {
  margin-right: 20px;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
}

.logo {
  width: 20%;
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo-img {
  width: 100%;
  margin: 0 10px;
}

/* HEADER TITLE */
.parallax {
  background-image: url("../../assets/agri-lp-cover3.jpg");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(35%);
}

.container-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 2;
}

.header-i-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 110px auto 0 auto;
}

.h-message {
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 1px 1px 6px #000;
}

.li-header {
  font-size: 1.5rem;
  color: #fff;
  text-shadow: 1px 1px 6px #000;
}

.li-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 1px 1px 6px #000;
  margin: 10px 0;
}

.li-container .li-items li {
  display: flex;
  flex-direction: column;
  padding: 5px;
  list-style-type: none;
  border-radius: 4px;
  margin: 2px 0;
  width: 31.5%;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  text-align: center;
}

.li-container .li-items li .icon {
  font-size: 3rem;
}

.li-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.h-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.7rem;
  font-weight: bold;
  text-shadow: 1px 1px 6px #000;
}

/* FOOTER */
footer {
  display: flex;
  flex-direction: row;
  height: 80px;
  background-color: #333;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  bottom: 0;
  position: relative;
  width: 100%;
  z-index: 4;
}

.foo-logo-container {
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: hidden;
}

footer .foo-r,
.foo-l {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.foo-l {
  flex: 1;
}

.foo-r {
  flex: 2;
}

footer a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}

footer .foo-link {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

footer .foo-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

footer .small-logo-container {
  display: flex;
  align-items: center;
}

.small-logo-container .small-logo {
  height: 66px;
}

footer .foo-link-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-self: center;
}

.flag {
  height: 66px;
}

.agr-t {
  font-size: 10px;
  text-decoration: none;
  margin: 0 10px;
}

.agr-t:hover {
  cursor: default;
}

.agr-t a {
  text-decoration: none;
}

@media (max-width: 830px) {

  /* HEADER */
  .h-message {
    font-size: 2rem;
  }

  .li-header {
    font-size: 1.3rem;
  }

  .li-container .li-items li {
    font-size: 0.9rem;
  }

  .li-container .li-items li .icon {
    font-size: 2.5rem;
  }

  .h-text {
    font-size: 1.6rem;
  }


  /* FOOTER */
  footer {
    height: 102px;
    overflow: hidden;
    align-self: flex-end;
  }

  .small-logo-container .small-logo {
    height: 56px;
  }

  .flag {
    height: 46px;
  }

  .foo-r {
    justify-content: start;
    flex: 1;
    font-size: 0.9rem;
  }

  .dot-split {
    display: none;
  }
}

@media (max-width: 700px) {

  /* NAVIGATION */
  .logo {
    width: 30%;
  }

  /* HEADER */
  .header-i-container {
    height: 150px;
    margin: 100px auto 0 auto;
  }

  .h-message {
    font-size: 1.3rem;
  }

  .li-header {
    font-size: 1rem;
    color: #fff;
    text-shadow: 1px 1px 6px #000;
  }

  .li-container .li-items li {
    width: 47%;
    font-size: 0.75rem;
  }

  .li-container .li-items li .icon {
    font-size: 1.5rem;
  }

  .h-text {
    font-size: 1rem;
  }

  .foo-r {
    justify-content: start;
    flex: 1;
    font-size: 0.8rem;
  }


  .small-logo-container .small-logo {
    height: 44px;
  }

  .flag {
    height: 42px;
  }
}

@media (max-width: 528px) {

  /* NAV */
  .logo {
    width: 45%;
    padding: 0;
  }

  .nav {
    font-size: 0.9rem;
  }

  /* HEADER */
  .header-i-container {
    height: 150px;
    margin: 100px auto 0 auto;
  }

  .h-message {
    font-size: 1.2rem;
  }

  footer {
    height: 120px;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
  }

  .foo-r {
    width: 100%;
    justify-content: space-between;
  }

}