.partners-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    overflow: hidden;
}

.partners-window {
    background: #fff;
    padding: 25px;
    width: 70%;
    height: 80%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.pw-close-btn {
    color: #c91d12;
    font-weight: bold;
    font-size: 1.2rem;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
}
.pw-close-btn:hover {
    opacity: 0.9;
    cursor: pointer;
}

.partners-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
    justify-items: center;
    align-items: start;
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.partners-content::-webkit-scrollbar {
    display: none;
}

.partner-card {
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 160px;
    justify-content: center;
    align-items: center;
}

.partner-card:hover {
    cursor: pointer;
    opacity: 0.9;
}

@media (max-width: 700px) {
    .partners-window {
        height: 90%;
        width: 100%;
    }
}